import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStarHalfStroke } from "@fortawesome/free-solid-svg-icons";
import { faStar } from "@fortawesome/free-solid-svg-icons";
import { faStar as faRegularStar } from "@fortawesome/free-regular-svg-icons";
import * as React from 'react';
export default {
  FontAwesomeIcon,
  faStarHalfStroke,
  faStar,
  faRegularStar,
  React
};